import App from './components/App'
import Embed from './components/Embed'

export default function setContents(p = {}) {

    const { wapp } = p

    function getTitle({ wapp, /*req,*/ res, title }) {
        const config = wapp.getTargetObject().config
        const { siteName = 'Wapplr' } = config
        const { statusCode, statusMessage, errorMessage } = res.wappResponse
        if (statusCode === 404) {
            title = statusMessage || 'Not found'
        }
        if (statusCode === 500) {
            title = errorMessage || statusMessage || 'Internal Server Error'
        }
        return title + ' | ' + siteName
    }

    const routes = [
        { path: '/', contentName: 'Home', title: 'Getting started' },
        { path: '/Container', contentName: 'Container' },
        { path: '/Color', contentName: 'Color' },
        { path: '/Typography', contentName: 'Typography' },
        { path: '/SvgIcon', contentName: 'SvgIcon' },
        { path: '/Paper', contentName: 'Paper' },
        { path: '/Button', contentName: 'Button' },
        { path: '/Label', contentName: 'Label' },
        { path: '/MenuItem', contentName: 'MenuItem' },
        { path: '/Input', contentName: 'Input' },
        { path: '/Modal', contentName: 'Modal' },
        { path: '/Menu', contentName: 'Menu' },
        { path: '/AppBar', contentName: 'AppBar' },
        { path: '/Image', contentName: 'Image' },
        { path: '/Avatar', contentName: 'Avatar' },
        { path: '/Logo', contentName: 'Logo' }
    ]

    routes.forEach(({ contentName, title }) => {

        wapp.contents.add({
            [contentName]: {
                render: App,
                description: contentName,
                renderType: 'react',
                title: function(p) {
                    return getTitle({ ...p, title: title || contentName })
                }
            }
        })

        wapp.contents.add({
            ['Embed' + contentName]: {
                render: Embed,
                description: contentName,
                renderType: 'react',
                title: function(p) {
                    return getTitle({ ...p, title: title || contentName })
                }
            }
        })

    })

    wapp.router.replace(routes)

    wapp.router.replace(routes.map(({ path, contentName }) => ({
        path: '/embed' + path,
        contentName: 'Embed' + contentName
    })))

}
