import wapplrPwa from 'wapplr-pwa'

export default function initPwa({wapp}) {

    wapp.pwaDefaultProps = {
        onlineHandler: ()=>{
            console.log("[PWA] Online again")
            if (wapp.client.onlineHandler) {
                wapp.client.onlineHandler();
            }
        },
        offlineHandler: ()=>{
            console.log("[PWA] Lost connection")
            if (wapp.client.offlineHandler) {
                wapp.client.offlineHandler();
            }
        },
        serviceWorkerResolve: ({reg})=>{

            console.log("[PWA] Serviceworker registered");

            reg.addEventListener("updatefound", function() {
                if (navigator.serviceWorker.controller) {
                    let installingWorker = reg.installing;
                    installingWorker.onstatechange = function() {
                        console.info("[PWA] Serviceworker state:", installingWorker.state);
                        // eslint-disable-next-line default-case
                        switch(installingWorker.state) {
                            case 'installed':
                                navigator.serviceWorker.ready
                                    .then(function (reg) {
                                        if (wapp.client.serviceWorkerUpdated) {
                                            console.log("[PWA] Serviceworker updated, page should reload...")
                                            wapp.client.serviceWorkerUpdated(reg);
                                        }
                                    });
                                break;
                            case 'redundant':
                                console.log('[PWA] The installing service worker became redundant')
                        }
                    }
                }
            });

        }
    }

    wapp.pwaUnregister = async function() {
        await new Promise((resolve, reject)=>{
            try {
                navigator.serviceWorker.getRegistrations().then(async function(registrations) {
                    await Promise.all(registrations.map(async function(reg) {
                        return await reg.unregister();
                    }));
                    console.log('[APP] Serviceworker unregistered')
                    resolve()
                })
            } catch (e) {
                console.log('[APP] Serviceworker unregister fail', e)
                reject(e)
            }
        })
    }

    wapp.pwaClearCaches = async function() {
        return await new Promise(async (resolve, reject)=>{
            try {
                let cacheNames = await caches.keys();
                const clearedCaches = await Promise.all(
                    cacheNames.map(function(cacheName) {
                        return caches.delete(cacheName);
                    })
                );
                console.log('[APP] Serviceworker caches cleared', cacheNames)
                resolve(clearedCaches)
            } catch (e) {
                console.log('[APP] Serviceworker caches fail', e)
                reject(e)
            }
        })
    }

    wapp.pwaReload = async function() {

        await wapp.pwaUnregister();

        if (wapp.pwa.addedCheckConnectivityListener) {
            window.removeEventListener("online", wapp.pwa.addedCheckConnectivityListener, false);
            window.removeEventListener("offline", wapp.pwa.addedCheckConnectivityListener, false);
        }

        delete wapp.pwa;

        await wapp.pwaInit()

    }

    wapp.pwaInit = async function() {
        return await wapplrPwa({ wapp,
            ...wapp.pwaDefaultProps
        })
    }

}
