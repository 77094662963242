import React from 'react'

const AppContext = React.createContext({
        labels: {},
        menus: {},
        messages: {},
        titles: {},
        template: null,
        device: null
    }
)

export default AppContext

