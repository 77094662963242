import React, { lazy, Suspense } from 'react'

const Lazy = lazy(() => import('./sync'))

export default React.forwardRef((props, ref) => {
    return (
        <Suspense fallback={<div></div>}>
            <Lazy {...props} ref={ref} />
        </Suspense>
    )
})
