import React, { useContext } from 'react'
import App from '../App'
import { WappContext } from 'wapplr-react/dist/common/Wapp'
import defaultStyle from './component.css'

export default function(props) {

    const context = useContext(WappContext)
    const { wapp } = context

    const { classNames = defaultStyle } = props

    wapp.styles.use(classNames)

    return (
        <div className={classNames.container}>
            <App
                {...props}
                parentRoute={'/embed'}
                fullPage={false}
            />
        </div>
    )
}
